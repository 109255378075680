<template>
  <div>
    <h1 class="title">
      Unlock your ideal leads <span>with Leads AI</span>
    </h1>
    <p class="sub-title">
      Share with AI your leads preferences and get instant, tailored results that will <span>supercharge your sales game!</span>
    </p>
    <tailored-leads-form
      :is-form-loading="isFormLoading"
      @form-submit="handleFormSubmit"
      @duplicate-search="scrollToResults"
    />
    <div
      v-if="totalResults !== null"
      class="results-section"
    >
      <p
        v-if="totalResults > 0"
        class="results-title"
      >
        Leads AI found <span class="purple-text">{{ totalResults > 100 ? '100+' : totalResults }} tailored lead{{ totalResults > 1 ? 's' : '' }}</span> for you!
      </p>
      <div class="grid-container">
        <LeadAICard
          v-for="lead in results"
          :key="lead.id"
          :lead="lead"
          :on-view-details="() => fetchLeadDetails(lead)"
        />
      </div>
    </div>
    <section
      v-if="shouldShowInfiniteLoader"
      class="mt-4"
    >
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-spinner
            id="leads-ai-loading-icon"
            class="loading-icon"
            label="Loading..."
          />
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<script>
import store from '@/store'
import { BSpinner, BCol, BRow } from 'bootstrap-vue'
import { FETCH_TAILORED_LEADS, GET_ONE_LEAD, SET_TAILORED_LEADS_FORM_DATA } from '@/store/modules/leadai.module'
import { apiToastError, apiToastWarning } from '@/@core/utils/toast'
import { elementInViewport } from '@/@core/utils/utils'
import LeadAICard from '../../LeadAICard.vue'
import TailoredLeadsForm from './TailoredLeadsForm.vue'

export default {
  name: 'LeadsAiTab',
  components: {
    TailoredLeadsForm,
    LeadAICard,
    BSpinner,
    BCol,
    BRow,
  },
  data() {
    return {
      isFormLoading: false,
      results: [],
      totalResults: null,
      formData: null,
      page: 1,
      limit: 5,
      searchAfter: '',
      isFetchingNextResults: false,
      continueLoading: false,
    }
  },
  computed: {
    shouldShowInfiniteLoader() {
      return this.totalResults > this.results.length && this.continueLoading
    },
  },
  created() {
    const existingData = store.state.leadai.tailoredLeadsFormData
    if (existingData) {
      this.results = existingData?.results
      this.page = existingData?.page
      this.totalResults = existingData?.totalResults
      this.searchAfter = existingData?.searchAfter
      this.continueLoading = existingData?.results.length < existingData?.totalResults
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScrollLoader)
  },
  unmounted() {
    window.removeEventListener('scroll', this.onScrollLoader)
  },
  methods: {
    onScrollLoader() {
      if (!this.isFetchingNextResults && elementInViewport(document.getElementById('leads-ai-loading-icon'))) {
        this.isFetchingNextResults = true
        this.fetchCompaniesResponse()
      }
    },
    async fetchLeadDetails(lead) {
      try {
        const res = await store.dispatch(GET_ONE_LEAD, {
          domain: lead?.domain,
          storeName: lead?.storeName,
        })
        if (res?.data) {
          this.$router.push({ name: 'leads-gen-ai-detail', params: { leadId: res?.data?.id } })
        }
      } catch (err) {
        this.handleFetchLeadDetailError(err)
      }
    },
    handleFetchLeadDetailError(err) {
      if (err.response?.status === 410) {
        this.$bvModal.show('modal-lead-details')
        this.error = err.response.data?.details?.leadsAiType || 'Unknown error'
      } else {
        apiToastWarning(
          'Failed to load the lead details page. Please try again.',
        )
      }
    },
    async fetchCompaniesResponse() {
      if (this.formData) {
        try {
          const payload = {
            ...this.formData,
            page: this.page,
            limit: this.limit,
          }
          if (this.searchAfter) {
            payload.searchAfter = this.searchAfter
          }
          const apiResponse = await store.dispatch(FETCH_TAILORED_LEADS, payload)
          this.handleApiResponse(apiResponse)
        } catch (err) {
          apiToastError(err)
          this.isFormLoading = false
          this.isFetchingNextResults = false
        }
      }
    },
    handleApiResponse(apiResponse) {
      if (apiResponse.data?.totalCount === 0) {
        this.continueLoading = false
        this.isFormLoading = false

        apiToastError('No leads found. Please try with different inputs.')
        return
      }
      this.results.push(...apiResponse.data.companies)
      this.totalResults = apiResponse.data?.totalCount ?? this.totalResults
      this.isFormLoading = false
      this.continueLoading = this.results.length < this.totalResults
      if (this.page === 1) {
        if (apiResponse.data?.searchAfter) {
          this.searchAfter = apiResponse.data?.searchAfter
        }
        setTimeout(() => this.scrollToResults(), 100)
      }
      this.page++
      this.isFetchingNextResults = false
      store.commit(SET_TAILORED_LEADS_FORM_DATA, {
        results: this.results,
        totalResults: this.totalResults,
        page: this.page,
        searchAfter: this.searchAfter,
      })
    },
    async handleFormSubmit(data) {
      this.isFormLoading = true
      this.formData = data
      this.page = 1
      this.results = []
      this.totalResults = null

      await this.fetchCompaniesResponse()
    },
    scrollToResults() {
      const resultSection = document.getElementsByClassName('results-section')

      if (resultSection.length) {
        window.scrollBy({ top: 500, behavior: 'smooth' })
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/@core/scss/base/bootstrap-extended.scss';

.title {
  font-size: 32.471px;
  font-weight: normal;
  color: $body-color;
  margin-top: 23px;
  line-height: 42px;
  margin-bottom: 9px;
  position: relative;
  left: -2px;
  span {
    color: #8d82fd;
    font-weight: initial;
  }
}

.sub-title {
  font-size: 18px;
  max-width: 575px;
  margin-bottom: 22px;

  span {
    color: #8d82fd;
    font-weight: bold;
    line-height: 23px;
  }
}

.results-section {
  .results-title {
    font-size: 18px;
    line-height: 23.5px;
    margin-top: 29px;
    margin-bottom: 1rem;

    span.purple-text {
      color: #8d82fd;
      font-weight: 700;
    }
  }
}
</style>
